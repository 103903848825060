import "./index.scss";
import UserAccount from "./userAccount";
import { NavLink, useLocation } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { layoutAction } from "../../redux/Layout/layoutSlice";
import { useTranslation } from "react-i18next";

const Sidebar = () => {
  const dispatch = useDispatch();
  const location = useLocation()?.pathname;
  console.log(location);
  const [isClassActive, setIsClassActive] = useState(false);
  const [expanded, setExpanded] = React.useState("panel1");

  const [t] = useTranslation();

  const role = useSelector((state) => state.auth.data?.role);
  const accordionName = useSelector((state) => state.layout.accordion);
  console.log("accordionName", accordionName);
  useEffect(() => {
    // switch (location) {
    //   case location.includes("requestList"):
    //     dispatch(layoutAction.sideBarAccordion("tms"));

    //   default:
    //     return null;
    // }
    if (
      location.includes("requestList") ||
      location.includes("production-plan")
    ) {
      dispatch(layoutAction.sideBarAccordion("tms"));
    } else if (
      location.includes("category") ||
      location.includes("defects") ||
      location.includes("forms")
    ) {
      dispatch(layoutAction.sideBarAccordion("qms"));
    } else if (
      location.includes("products") ||
      location.includes("components") ||
      location.includes("productionstations") ||
      location.includes("schedule") ||
      location.includes("calendar") ||
      location.includes("train-setup")
    ) {
      dispatch(layoutAction.sideBarAccordion("setup"));
    } else if (location.includes("users")) {
      dispatch(layoutAction.sideBarAccordion("account"));
    } else {
      dispatch(layoutAction.sideBarAccordion(false));
    }
  }, [location]);

  const myClass = isClassActive ? "showSidebar " : "";
  let toggleNav = () => {
    setIsClassActive(!isClassActive);
  };

  const handleChange = (panel) => (event, newExpanded) => {
    dispatch(layoutAction.sideBarAccordion(panel));
  };
  useEffect(() => {
    setExpanded(accordionName);
  }, [accordionName]);
  return (
    <>
      <div className="container-fluid d-flex justify-content-between">
        <div className="navbar-btn toglleNav">
          <button className="btn-toggle-offcanvas" onClick={toggleNav}>
            <i className="lnr lnr-menu fa fa-bars"></i>
          </button>
        </div>
      </div>
      <div
        id="left-sidebar"
        className={`sidebar ${myClass}`}
        style={{ zIndex: 9 }}
      >
        <UserAccount />

        {role === "client_admin" && (
          <>
            <Accordion
              expanded={expanded === "tms"}
              onChange={handleChange("tms")}
              className="m-0"
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel3a-content"
                id="panel3a-header"
              >
                <i className="icon-directions"></i>
                <Typography style={{ fontSize: "12px", padding: "0 10px" }}>
                  TMS
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography component="div">
                  <ul>
                    <NavLink
                      to="/admin/"
                      onClick={() => setIsClassActive(false)}
                    >
                      <li className="list">{t("dashboard")}</li>
                    </NavLink>
                    <NavLink
                      to="/admin/requestList"
                      onClick={() => setIsClassActive(false)}
                    >
                      <li className="list">{t("deliveries")}</li>
                    </NavLink>
                    <NavLink
                      to="/admin/production-plan"
                      onClick={() => setIsClassActive(false)}
                    >
                      <li className="list">{t("productionPlan")}</li>
                    </NavLink>
                  </ul>
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === "qms"}
              onChange={handleChange("qms")}
              className="m-0"
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel4a-content"
                id="panel4a-header"
              >
                <i className="icon-check"></i>
                <Typography style={{ fontSize: "12px", padding: "0 10px" }}>
                  QMS
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography component="div">
                  <ul>
                    <NavLink
                      to="/admin/"
                      onClick={() => setIsClassActive(false)}
                    >
                      <li className="list">{t("dashboard")}</li>
                    </NavLink>
                    <NavLink
                      to="/admin/qms/category"
                      onClick={() => setIsClassActive(false)}
                    >
                      <li className="list">{t("Category")}</li>
                    </NavLink>
                    <NavLink
                      to="/admin/qms/defects"
                      onClick={() => setIsClassActive(false)}
                    >
                      <li className="list">{t("occurrences")}</li>
                    </NavLink>
                    <NavLink
                      to="/admin/qms/forms"
                      onClick={() => setIsClassActive(false)}
                    >
                      <li className="list">{t("forms")}</li>
                    </NavLink>
                  </ul>
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === "mpm"}
              onChange={handleChange("mpm")}
              className="m-0 mb-3"
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel4a-content"
                id="panel4a-header"
              >
                <i className="fa fa-dropbox"></i>
                <Typography style={{ fontSize: "12px", padding: "0 10px" }}>
                  MPM
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography component="div">
                  <ul>
                    <NavLink
                      to="/admin/MPM"
                      onClick={() => setIsClassActive(false)}
                    >
                      <li className="list">{t("dashboard")}</li>
                    </NavLink>
                  </ul>
                </Typography>
              </AccordionDetails>
            </Accordion>
            <hr className="mx-3" />

            {role === "client_admin" && (
              <>
                <Accordion
                  expanded={expanded === "setup"}
                  onChange={handleChange("setup")}
                  className="m-0"
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                  >
                    <i className="icon-settings"></i>
                    <Typography style={{ fontSize: "12px", padding: "0 10px" }}>
                      {t("setup")}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography component="div">
                      <ul>
                        <NavLink
                          to="/admin/products"
                          onClick={() => setIsClassActive(false)}
                        >
                          <li className="list">{t("products")}</li>
                        </NavLink>
                        <NavLink
                          to="/admin/components"
                          onClick={() => setIsClassActive(false)}
                        >
                          <li className="list">{t("components")}</li>
                        </NavLink>
                        <NavLink
                          to="/admin/productionstations"
                          onClick={() => setIsClassActive(false)}
                        >
                          <li className="list">{t("productionStations")}</li>
                        </NavLink>
                        <NavLink
                          to="/admin/productionstations"
                          onClick={() => setIsClassActive(false)}
                        >
                          <li className="list">{t("defects")}</li>
                        </NavLink>
                        <NavLink
                          to="/admin/schedule"
                          onClick={() => setIsClassActive(false)}
                        >
                          <li className="list">{t("schedule")}</li>
                        </NavLink>
                        <NavLink
                          to="/admin/calendar"
                          onClick={() => setIsClassActive(false)}
                        >
                          <li className="list">{t("calendar")}</li>
                        </NavLink>
                        <NavLink
                          to="/admin/train-setup"
                          onClick={() => setIsClassActive(false)}
                        >
                          <li className="list">{t("tmsSetup")}</li>
                        </NavLink>
                      </ul>
                    </Typography>
                  </AccordionDetails>
                </Accordion>
                <Accordion
                  expanded={expanded === "account"}
                  onChange={handleChange("account")}
                  className="m-0"
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <i className="icon-user"></i>

                    <Typography style={{ fontSize: "12px", padding: "0 10px" }}>
                      {t("account")}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography component="div">
                      <ul>
                        <NavLink
                          to="/admin/users"
                          onClick={() => setIsClassActive(false)}
                        >
                          <li className="list">{t("manage")}</li>
                        </NavLink>
                        <NavLink
                          to="/admin/users"
                          onClick={() => setIsClassActive(false)}
                        >
                          <li className="list">{t("users")}</li>
                        </NavLink>
                      </ul>
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default Sidebar;
