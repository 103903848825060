import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import TableList from "../../components/TableList";
import PageTitle from "../../components/PageTitle";
import TablePagination from "../../components/Pagination";
import Switch from "../../components/SwitchToglle";
import PopUp from "../../components/PopUp";
import ProductionStationsAdd from "./productionStationsAdd";
import ProductionStationEdit from "./productionStationsEdits";
import BackButton from "../../components/Buttons/backButton";
import { useSelector, useDispatch } from "react-redux";
import DeleteLine from "./deleteLine";
import {
  findAllLines,
  editLine,
} from "../../redux/ProductionLines/ProductionLinesActions";
import "./index.scss";
import PagesLoading from "../../components/Loading/pagesLoading";
import setTitle from "../../hooks/Title";
import { useTranslation } from "react-i18next";
import socket from "../../utils/webSoket/webSocket";

import { FilterMatchMode, FilterOperator } from "primereact/api";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
const ProductionStations = () => {
  const dispatch = useDispatch();

  const [t] = useTranslation();

  let token = useSelector((state) => state.auth.data?.accessToken);

  const [filterVisibility, setFilterVisibility] = useState(false);
  const [currentItems, setCurrentItems] = useState([]);
  const [isVisible, setIsVisible] = useState(false);
  const [isEditVisible, setIsEditVisible] = useState(false);
  const [isDeleteVisible, setIsDeleteVisible] = useState(false);
  const [expandedAccordion, setExpandedAccordion] = useState(null);
  const [lineId, setLineId] = useState("");

  // Filter States
  const [name, setName] = useState("");
  const [deliveryStationName, setDeliveryStationName] = useState("");

  const [dataInfo, setDataInfo] = useState(null);
  const [filters, setFilters] = useState(null);

  const [currentPage, setCurrentPage] = useState(1);

  const [itemsPerPage] = useState(14);

  let lines = useSelector((state) => state.productionLines.lines);
  let loading = useSelector((state) => state.productionLines?.singleLoading);
  useEffect(() => {
    setDataInfo(currentItems);
    initFilters();
  }, [currentItems]);
  const initFilters = () => {
    setFilters({
      name: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
      },
      stations: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
      },
    });
  };
  const renderHeader = () => {
    return (
      <div className="flex justify-content-between">
        <Button
          type="button"
          label="Clear"
          outlined
          style={{
            padding: "4px 8px",
            color: "black",
            opacity: "0.6",
          }}
          onClick={() => {
            dispatch(
              findAllLines({
                token: token,
                currentPage,
                itemsPerPage,
              })
            );
          }}
        />
      </div>
    );
  };
  const headers = renderHeader();

  socket.on("created_production_lines", (info) => {
    const updatedCurrentItems = [info, ...currentItems];
    setCurrentItems(updatedCurrentItems);
  });

  socket.on("updated_production_lines", (info) => {
    setCurrentItems(
      currentItems.map((obj) => {
        if (obj.id === info.id) {
          return { ...info };
        }
        return obj;
      })
    );
  });

  socket.on("deleted_production_lines", (info) => {
    const filtered = currentItems.filter((item) => item.id !== info.id);
    setCurrentItems(filtered);
  });

  useEffect(() => {
    setTitle("Production Stations");
    dispatch(
      findAllLines({
        token: token,
        currentPage,
        itemsPerPage,
        name,
        deliveryStationName,
      })
    );
  }, [token, currentPage, itemsPerPage, name, deliveryStationName]);

  const handleSwitch = (newValue, id) => {
    let switchStatus;
    if (newValue === true) {
      switchStatus = 1;
    } else {
      switchStatus = 0;
    }
    dispatch(
      editLine({
        status: switchStatus,
        id: id,
        token: token,
      })
    );
  };

  const handleDeleteLine = (lineId) => {
    setLineId(lineId);
    setIsDeleteVisible(true);
  };

  const closeDeletehandle = () => {
    setIsDeleteVisible(false);
  };
  const closeAddHandle = () => {
    setIsVisible(false);
  };
  const closeEditHandle = () => {
    setIsEditVisible(false);
  };
  const filteredData = (dataT) => {
    setCurrentItems(dataT);
  };

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpandedAccordion(isExpanded ? panel : null);
  };
  const stationsLine = (rowData) => {
    const isAccordionExpanded = expandedAccordion === rowData.id;
    return (
      <Accordion
        expanded={isAccordionExpanded}
        onChange={handleAccordionChange(rowData.id)}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>{t("stations")}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div>
            <ul style={{ listStyle: "none" }}>
              {rowData.stations.map((station) => (
                <li key={station.id}>{` ${station.name}`}</li>
              ))}
            </ul>
          </div>
        </AccordionDetails>
      </Accordion>
    );
  };

  const elementAction = (rowData) => {
    return (
      <>
        <Link
          className="btn btn-outline-secondary"
          onClick={() => {
            setIsEditVisible(true);
            setLineId(rowData.id);
          }}
        >
          <i className="icon-pencil"></i>
        </Link>
        <Link
          className="btn btn-outline-danger danger "
          onClick={() => handleDeleteLine(rowData.id)}
        >
          <i className="icon-trash"></i>
        </Link>
      </>
    );
  };

  const switchLine = (rowData) => {
    return (
      <Switch
        ischecked={rowData.status}
        handleSwitch={handleSwitch}
        id={rowData.id}
      />
    );
  };

  const nameFilterTemplate = () => {
    return (
      <div>
        <input
          className="form-control"
          placeholder="Line Name"
          onChange={(e) => setName(e.target.value)}
        />
      </div>
    );
  };

  const stationFilterTemplate = () => {
    return (
      <div>
        <input
          className="form-control"
          placeholder="Station Name"
          onChange={(e) => setDeliveryStationName(e.target.value)}
        />
      </div>
    );
  };

  return (
    <>
      <PopUp
        text={<DeleteLine closePopup={closeDeletehandle} id={lineId} />}
        isVisible={isDeleteVisible}
      />
      <PopUp
        text={<ProductionStationsAdd closePopup={closeAddHandle} />}
        isVisible={isVisible}
      />
      <PopUp
        text={
          <ProductionStationEdit closePopup={closeEditHandle} id={lineId} />
        }
        isVisible={isEditVisible}
      />
      <div id="main-content">
        <BackButton />
        <div className="flex-header">
          <PageTitle Title={t("productionStations")} />
          <div className="btn-style">
            <Link
              className="btn btn-outline-secondary"
              onClick={() => setIsVisible(true)}
            >
              <i className="icon-plus plus"></i>
            </Link>
          </div>
        </div>
        {filterVisibility && (
          <div className="card">
            <div className="inputs-search">
              <div className="">
                <input
                  className="form-control"
                  placeholder="Line Name"
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
              <div className="">
                <input
                  className="form-control"
                  placeholder="Station Name"
                  onChange={(e) => setDeliveryStationName(e.target.value)}
                />
              </div>
            </div>
          </div>
        )}
        <div className="table-list">
          <div className="table-responsive">
            <DataTable
              value={dataInfo}
              loading={loading}
              loadingOverlay={null}
              loadingIcon={<PagesLoading />}
              dataKey="id"
              filters={filters}
              header={headers}
              emptyMessage={t("noDataFound")}
            >
              <Column
                header={t("lines")}
                field="name"
                filter
                filterElement={nameFilterTemplate}
                style={{ minWidth: "9rem" }}
                showAddButton={false}
                showApplyButton={false}
                showClearButton={false}
                showFilterMenuOptions={false}
                // body={hrCodeUsers}
              />

              <Column
                header={t("stations")}
                field="stations"
                filter
                filterElement={stationFilterTemplate}
                style={{ minWidth: "9rem" }}
                showAddButton={false}
                showApplyButton={false}
                showClearButton={false}
                showFilterMenuOptions={false}
                body={stationsLine}
              />
              <Column
                header={t("actions")}
                style={{ minWidth: "9rem" }}
                body={elementAction}
              />
              <Column
                header={t("status")}
                style={{ minWidth: "9rem" }}
                body={switchLine}
              />
            </DataTable>

            <TablePagination
              data={lines?.productionLines}
              filterData={filteredData}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              responseData={lines}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductionStations;
