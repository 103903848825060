import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../services/axiosInstance";
import Api from "../endPoints";




export const updateAccount = createAsyncThunk(
  "account/updateAccount",
  async (
    {
        deliveryRates,
        token,
    },
    { rejectWithValue }
  ) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const filterEmptyFields = (obj) => {
        const filteredObj = {};
        for (const key in obj) {
          if (obj[key] !== "" && obj[key] !== null && obj[key].length !== 0) {
            filteredObj[key] = obj[key];
          }
        }
        return filteredObj;
      };

      const dataToUpdate = filterEmptyFields({
        deliveryRates,
      });

      const response = await axiosInstance.patch(
        `${Api.updateAccount}`,
        dataToUpdate,
        config
      );

      return response.data;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
