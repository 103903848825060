import React, { useState } from "react";
import { Calendar } from "primereact/calendar";

const DateOverrides = () => {
  const [dates, setDates] = useState([]);
  const [timeRanges, setTimeRanges] = useState([]);
  const [showCalendar, setShowCalendar] = useState(false);
  const [selectedDateRanges, setSelectedDateRanges] = useState([]);

  const handleDateChange = (e) => {
    const selectedDates = e.value;
    setDates(selectedDates);

    // Find the indexes of the dates that are removed
    const removedDateIndexes = dates
      .map((date) => !selectedDates.includes(date))
      .map((isRemoved, index) => (isRemoved ? index : -1))
      .filter((index) => index !== -1);

    // Remove the time ranges and selectedDateRanges for the removed dates
    if (removedDateIndexes.length > 0) {
      const updatedTimeRanges = timeRanges.filter(
        (_, index) => !removedDateIndexes.includes(index)
      );
      setTimeRanges(updatedTimeRanges);

      const updatedSelectedDateRanges = selectedDateRanges.filter(
        (_, index) => !removedDateIndexes.includes(index)
      );
      setSelectedDateRanges(updatedSelectedDateRanges);
    }

    // Add any new dates to timeRanges and selectedDateRanges
    const existingDateCount = timeRanges.length;
    if (selectedDates.length > existingDateCount) {
      const newDateCount = selectedDates.length;
      const addedDateCount = newDateCount - existingDateCount;

      const newRanges = Array.from({ length: addedDateCount }, () => [
        { startTime: "", endTime: "" },
      ]);
      const updatedTimeRanges = timeRanges.concat(newRanges);
      setTimeRanges(updatedTimeRanges);

      const newDateObjects = Array.from({ length: addedDateCount }, () => ({
        date: "",
        timeRanges: [],
      }));
      const updatedSelectedDateRanges =
        selectedDateRanges.concat(newDateObjects);
      setSelectedDateRanges(updatedSelectedDateRanges);
    }
  };

  const formatDate = (date) => {
    return date.toLocaleDateString(undefined, {
      day: "numeric",
      month: "long",
      year: "numeric",
    });
  };
  const handleTimeChange = (dateIndex, rangeIndex, field, value) => {
    const updatedTimeRanges = [...timeRanges];
    updatedTimeRanges[dateIndex][rangeIndex] = {
      ...updatedTimeRanges[dateIndex][rangeIndex],
      [field]: value,
    };

    // Validate the time range and check for conflicts with other time ranges
    const startTime = updatedTimeRanges[dateIndex][rangeIndex].startTime;
    const endTime = updatedTimeRanges[dateIndex][rangeIndex].endTime;

    if (
      field === "startTime" &&
      endTime !== "" &&
      new Date(startTime) >= new Date(endTime)
    ) {
      // If the start time is after or equal to the end time, prevent selection
      updatedTimeRanges[dateIndex][rangeIndex][field] = "";
    } else if (
      field === "endTime" &&
      startTime !== "" &&
      new Date(endTime) <= new Date(startTime)
    ) {
      // If the end time is before or equal to the start time, prevent selection
      updatedTimeRanges[dateIndex][rangeIndex][field] = "";
    } else if (rangeIndex > 0 && startTime === "") {
      // Set the start time of the current range to the end time of the previous range
      const previousEndTime =
        updatedTimeRanges[dateIndex][rangeIndex - 1].endTime;
      updatedTimeRanges[dateIndex][rangeIndex].startTime = previousEndTime;
    }

    // Check for conflicts with other time ranges
    const otherTimeRanges = timeRanges[dateIndex].filter(
      (_, index) => index !== rangeIndex
    );
    const conflictingRange = otherTimeRanges.find(
      (timeRange) =>
        (startTime !== "" &&
          new Date(startTime) >= new Date(timeRange.startTime) &&
          new Date(startTime) <= new Date(timeRange.endTime)) ||
        (endTime !== "" &&
          new Date(endTime) >= new Date(timeRange.startTime) &&
          new Date(endTime) <= new Date(timeRange.endTime)) ||
        (new Date(timeRange.startTime) !== "" &&
          new Date(timeRange.startTime) >= new Date(startTime) &&
          new Date(timeRange.startTime) <= new Date(endTime)) ||
        (new Date(timeRange.endTime) !== "" &&
          new Date(timeRange.endTime) >= new Date(startTime) &&
          new Date(timeRange.endTime) <= new Date(endTime))
    );

    if (conflictingRange) {
      // If there's a time conflict, clear the selected time
      updatedTimeRanges[dateIndex][rangeIndex][field] = "";
    }

    if (field === "endTime" && startTime === "") {
      // Set the start time of the next range to the current end time
      const nextStartTime =
        updatedTimeRanges[dateIndex][rangeIndex + 1]?.startTime;
      if (nextStartTime) {
        updatedTimeRanges[dateIndex][rangeIndex + 1].startTime = endTime;
      }
    }

    setTimeRanges(updatedTimeRanges);
  };

  const handleAddTimeRange = (dateIndex) => {
    const updatedTimeRanges = [...timeRanges];
    updatedTimeRanges[dateIndex].push({ startTime: "", endTime: "" });
    setTimeRanges(updatedTimeRanges);
  };

  const handleAddNewTimeRange = (dateIndex, rangeIndex) => {
    const currentRange = timeRanges[dateIndex][rangeIndex];
    const isCurrentRangeValid =
      currentRange.startTime !== "" && currentRange.endTime !== "";

    if (isCurrentRangeValid) {
      const updatedTimeRanges = [...timeRanges];
      updatedTimeRanges[dateIndex].splice(rangeIndex + 1, 0, {
        startTime: "",
        endTime: "",
      });
      setTimeRanges(updatedTimeRanges);
    } else {
      alert("Please fill in the current time range before adding a new one.");
    }
  };

  const handleDeleteTimeRange = (dateIndex, rangeIndex) => {
    const updatedTimeRanges = [...timeRanges];
    updatedTimeRanges[dateIndex].splice(rangeIndex, 1);
    setTimeRanges(updatedTimeRanges);

    const remainingTimeRanges = updatedTimeRanges[dateIndex];

    if (remainingTimeRanges.length === 0) {
      // If there are no time ranges left, remove the date and its object from both arrays
      const updatedDates = [...dates];
      updatedDates.splice(dateIndex, 1);
      setDates(updatedDates);

      const updatedSelectedDateRanges = [...selectedDateRanges];
      updatedSelectedDateRanges.splice(dateIndex, 1);
      setSelectedDateRanges(updatedSelectedDateRanges);

      // Remove the entire object representing the day from the timeRanges array
      const updatedTimeRangesWithoutDay = updatedTimeRanges.filter(
        (_, index) => index !== dateIndex
      );
      setTimeRanges(updatedTimeRangesWithoutDay);
    }
  };

  const handleButtonClick = () => {
    if (dates.length > 0 && timeRanges.length > 0) {
      const selectedDateRanges = [];

      // Add the selected days and time ranges to the array as objects
      dates.forEach((date, dateIndex) => {
        const selectedDate = date.toLocaleDateString(undefined, {
          day: "numeric",
          month: "long",
          year: "numeric",
        });

        const dateRangeObject = {
          date: selectedDate,
          timeRanges: [],
        };

        timeRanges[dateIndex].forEach((timeRange) => {
          if (timeRange.startTime && timeRange.endTime) {
            const startTime = new Date(timeRange.startTime);
            const endTime = new Date(timeRange.endTime);

            dateRangeObject.timeRanges.push({
              startTime: startTime.toLocaleTimeString([], {
                timeStyle: "short",
              }),
              endTime: endTime.toLocaleTimeString([], { timeStyle: "short" }),
            });
          }
        });

        selectedDateRanges.push(dateRangeObject);
      });

      // Log the array of objects to the console

      setSelectedDateRanges(selectedDateRanges);
    }

    setShowCalendar(false);
  };

  const formatTimeRanges = (ranges) => {
    let formattedRanges = [];
    ranges.forEach((range) => {
      let formattedRange = "";
      range.forEach((timeRange, rangeIndex) => {
        if (timeRange.startTime && timeRange.endTime) {
          const startTime = new Date(timeRange.startTime);
          const endTime = new Date(timeRange.endTime);
          const formattedStartTime = startTime.toLocaleTimeString([], {
            timeStyle: "short",
          });
          const formattedEndTime = endTime.toLocaleTimeString([], {
            timeStyle: "short",
          });
          formattedRange += `(${formattedStartTime} - ${formattedEndTime})`;
        }
      });
      formattedRanges.push(formattedRange);
    });
    return formattedRanges;
  };

  const renderTitle = (date, dateIndex) => {
    const selectedDate = new Date(date);
    const formattedDate = selectedDate.toLocaleDateString(undefined, {
      day: "numeric",
      month: "long",
      year: "numeric",
    });
    const dayName = selectedDate.toLocaleDateString(undefined, {
      weekday: "long",
    });

    return (
      <div key={dateIndex} className="text-left mt-2">
        <p>
          {formattedDate} ({dayName})
        </p>
        {timeRanges[dateIndex].map((timeRange, rangeIndex) => (
          <div key={rangeIndex}>
            <label
              htmlFor={`startTime_${dateIndex}_${rangeIndex}`}
              className=""
            ></label>
            <Calendar
              hourFormat="12"
              value={timeRange.startTime ? new Date(timeRange.startTime) : null}
              onChange={(e) =>
                handleTimeChange(
                  dateIndex,
                  rangeIndex,
                  "startTime",
                  e.target.value
                )
              }
              timeOnly
              style={{
                marginLeft: "10px",
                marginRight: "10px",
                width: "100px",
                height: "10px",
              }}
            />
            <label htmlFor={`endTime_${dateIndex}_${rangeIndex}`} className="">
              -
            </label>
            <Calendar
              hourFormat="12"
              value={timeRange.endTime ? new Date(timeRange.endTime) : null}
              onChange={(e) =>
                handleTimeChange(
                  dateIndex,
                  rangeIndex,
                  "endTime",
                  e.target.value
                )
              }
              timeOnly
              style={{
                marginLeft: "10px",
                marginRight: "10px",
                width: "100px",
                height: "10px",
              }}
            />
            {rangeIndex === 0 ? (
              timeRanges[dateIndex].length === 1 ? (
                <i
                  className="icon-plus"
                  onClick={() => handleAddTimeRange(dateIndex)}
                  style={{ marginLeft: "10px", cursor: "pointer" }}
                ></i>
              ) : (
                <i
                  className="icon-trash"
                  onClick={() => handleDeleteTimeRange(dateIndex, rangeIndex)}
                  style={{
                    marginLeft: "10px",
                    cursor: "pointer",
                    color: "red",
                  }}
                ></i>
              )
            ) : (
              rangeIndex === timeRanges[dateIndex].length - 1 && (
                <>
                  <i
                    className="icon-plus"
                    onClick={() => handleAddNewTimeRange(dateIndex, rangeIndex)}
                    style={{ marginLeft: "10px", cursor: "pointer" }}
                  ></i>
                  <i
                    className="icon-trash"
                    onClick={() => handleDeleteTimeRange(dateIndex, rangeIndex)}
                    style={{
                      marginLeft: "10px",
                      cursor: "pointer",
                      color: "red",
                    }}
                  ></i>
                </>
              )
            )}
            {rangeIndex !== 0 &&
              rangeIndex !== timeRanges[dateIndex].length - 1 && (
                <i
                  className="icon-trash"
                  onClick={() => handleDeleteTimeRange(dateIndex, rangeIndex)}
                  style={{
                    marginLeft: "10px",
                    cursor: "pointer",
                    color: "red",
                  }}
                ></i>
              )}
          </div>
        ))}
      </div>
    );
  };

  return (
    <div className="text-center">
      {!showCalendar && (
        <div>
          <h5
            className="text-center"
            style={{
              border: "2px solid #fdb813",
              padding: "8px 0 8px 0",
              borderRadius: "30px",
              margin: "0 auto",
            }}
            onClick={() => setShowCalendar(true)}
          >
            Add a date override
          </h5>

          {selectedDateRanges.length > 0 && (
            <div>
              {/* <h6>Selected Date Overrides:</h6> */}
              <ul
                style={{ listStyle: "none", padding: "0px", marginTop: "10px" }}
              >
                {selectedDateRanges.map((dateRange, index) => (
                  <li
                    key={index}
                    style={{
                      textAlign: "start",
                      display: "flex",
                      justifyContent: "space-between",
                      marginTop: "10px",
                    }}
                  >
                    <strong>{dateRange.date}</strong>
                    <ul style={{ listStyle: "none", padding: "0px" }}>
                      {dateRange.timeRanges.map((timeRange, idx) => (
                        <li key={idx}>
                          {timeRange.startTime} - {timeRange.endTime}{" "}
                          <i
                            className="icon-trash"
                            onClick={() => handleDeleteTimeRange(index, idx)}
                            style={{
                              marginLeft: "10px",
                              cursor: "pointer",
                              color: "red",
                            }}
                          ></i>
                        </li>
                      ))}
                    </ul>
                  </li>
                ))}
              </ul>

              <button
                className="btn  style-btn coloring"
                type="submit"
                onClick={() => {
                  console.log("overrides data send to api", selectedDateRanges);
                }}
              >
                Overrides
              </button>
            </div>
          )}
        </div>
      )}

      {showCalendar && (
        <>
          <Calendar
            value={dates}
            onChange={handleDateChange}
            inline
            selectionMode="multiple"
            minDate={new Date()}
          />

          {dates.map((date, index) => renderTitle(date, index))}

          <h5
            className="text-center"
            style={{
              border: "2px solid #fdb813",
              padding: "8px 0 8px 0",
              borderRadius: "30px",
              margin: "10px auto 0 auto",
            }}
            onClick={handleButtonClick}
          >
            Confirm override
          </h5>
        </>
      )}
    </div>
  );
};

export default DateOverrides;
