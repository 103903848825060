import React, { useState } from "react";
import BackButton from "../../components/Buttons/backButton";
import { useSelector, useDispatch } from "react-redux";
import { updateAccount } from "../../redux/Account/AccountAction";
import UploadImageToS3 from "../../components/UploadImageToS3/UploadImageToS3";
import { Formik, Form, Field } from "formik";
import { useTranslation } from "react-i18next";
import SuccessPage from "../../components/SuccessPage";

const TrainSetUP = () => {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.auth.data?.accessToken);
  const [t] = useTranslation();

  // State to control the visibility of the success page
  const [showSuccessPage, setShowSuccessPage] = useState(false);

  const handleSubmit = (values) => {
    dispatch(
      updateAccount({
        token: token,
        deliveryRates: values.deliveryRates,
      })
    );
  };

  // Function to handle the click event for the separate button
  const handleShowSuccessPage = () => {
    setShowSuccessPage(true);
  };

  const handleCloseSuccessPage = () => {
    setShowSuccessPage(false);
  };

  return (
    <div id="main-content">
      <BackButton />
      <Formik
        initialValues={{
          deliveryRates: "",
        }}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting }) => (
          <Form>
            <div
              className="card"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: "20px",
                gap: "10px",
              }}
            >
              <Field
                type="number"
                name="deliveryRates"
                className="form-control pe-5 w-50"
                placeholder={t("enterDeliveryRate")}
              />
              <button
                className="btn btn-primary  primary-btn-style"
                type="submit"
              >
                {t("save")}
              </button>
            </div>
          </Form>
        )}
      </Formik>

      <UploadImageToS3 />

      {/* Button to show the success page */}
      <button
        className="btn btn-success"
        onClick={handleShowSuccessPage}
      >
        Show Success Page
      </button>

      {/* Conditional rendering of the success page */}
      <SuccessPage isVisible={showSuccessPage} onClose={handleCloseSuccessPage} />
    </div>
  );
};

export default TrainSetUP;
