import PageTitle from "../../components/PageTitle";
import imageuser from "../../assets/images/user.jpg";
import "./index.scss";
import { useState, useEffect } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useSelector, useDispatch } from "react-redux";
import { ChangePasswordSignIn, updateUser } from "../../redux/Auth/AuthActions";
import { Circles } from "react-loader-spinner";
import BackButton from "../../components/Buttons/backButton";
import { useTranslation } from "react-i18next";
import setTitle from "../../hooks/Title";
const Profile = () => {
  const dispatch = useDispatch();
  const [t] = useTranslation();

  const [image, setImage] = useState(imageuser);

  let token = useSelector((state) => state.auth.data?.accessToken);
  let Loading = useSelector((state) => state.auth.singleLoading);

  useEffect(() => {
    setTitle("Profile");
  }, []);
  const handleImageChange = (e) => {
    const selectedImage = e.target.files[0];
    const reader = new FileReader();

    reader.onload = (event) => {
      setImage(event.target.result);
    };

    reader.readAsDataURL(selectedImage);
  };

  const update = useFormik({
    initialValues: {
      displayName: "",
    },
    enableReinitialize: false,
    validationSchema: Yup.object({
      displayName: Yup.string().required("Please enter your Full Name."),
    }),
    onSubmit: (values) => {
      dispatch(
        updateUser({
          displayName: values.displayName,
          token: token,
        })
      );
      console.log(values.displayName);
    },
  });

  const validation = useFormik({
    initialValues: {
      oldPassword: "",
      newPassword: "",
      confirmNewPassword: "",
    },
    enableReinitialize: false,
    validationSchema: Yup.object({
      oldPassword: Yup.string().required("Please enter the old password."),
      newPassword: Yup.string().required("Please enter the new password."),

      confirmNewPassword: Yup.string()
        .oneOf([Yup.ref("newPassword")], "Passwords must match.")
        .required("Please confirm the new password."),
    }),
    onSubmit: (values) => {
      dispatch(
        ChangePasswordSignIn({
          oldPassword: values.oldPassword,
          newPassword: values.confirmNewPassword,
          token: token,
        })
      );
    },
  });
  return (
    <>
      <div id="main-content">
        <BackButton />
        <PageTitle Title="Profile Settings" />
        <div className="profile">
          <div className="card p-3">
            <div className="body">
              <h6>{t("profilePhoto")}</h6>
              <div className="media">
                <div className="media-left m-r-15">
                  <img
                    alt="User"
                    className="user-photo media-object"
                    src={image}
                  />
                </div>
                <div className="media-body">
                  <p>
                    {t("UploadYourPhoto")}
                    <br />
                    <em>{t("PhotoDiemention")}</em>
                  </p>
                  <input
                    className="btn btn-default-dark"
                    id="inputImage"
                    type="file"
                    onChange={handleImageChange}
                  />

                  <input className="sr-only" id="filePhoto" type="file" />
                </div>
              </div>
            </div>

            <div className="body">
              <h6>{t("basicInformation")}</h6>
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  update.handleSubmit();
                  return false;
                }}
              >
                <input
                  type="text"
                  name={t("username")}
                  style={{ display: "none" }}
                  aria-hidden="true"
                  tabIndex="-1"
                />
                <div className="row clearfix">
                  <div className="col-lg-6 col-md-12">
                    <div className="form-group">
                      <input
                        className="form-control"
                        placeholder="Full Name"
                        type="text"
                        name={t("displayName")}
                        onChange={update.handleChange}
                        onBlur={update.handleBlur}
                        value={update.values.displayName || ""}
                      />
                      {update.touched.displayName &&
                      update.errors.displayName ? (
                        <p style={{ color: "red" }}>
                          {update.errors.displayName}
                        </p>
                      ) : null}
                    </div>
                  </div>

                  <div className="col-lg-6 col-md-12">
                    <div className="form-group ml-2">
                      <h6>{t("hr-code")}</h6>
                      <p>21341234</p>
                    </div>
                    <div className="form-group ml-2">
                      <h6>{t("emailAddress")}</h6>
                      <p>user@gmail.com</p>
                    </div>
                  </div>
                </div>
                <button
                  className="btn btn-primary"
                  style={{
                    backgroundColor: "#fdb813",
                    borderColor: "#fdb813",
                    boxShadow: "none",
                  }}
                  type="submit"
                >
                  {Loading ? (
                    <Circles
                      height="20"
                      width="40"
                      color="white"
                      ariaLabel="circles-loading"
                      wrapperStyle={{}}
                      wrapperClass=""
                      visible={true}
                    />
                  ) : (
                    t("update")
                  )}
                </button>
                &nbsp;&nbsp;
                {/* <button className="btn btn-default" type="button">
                  Cancel
                </button> */}
              </form>
            </div>

            <div className="body">
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  validation.handleSubmit();
                  return false;
                }}
              >
                <div className="col-lg-6 col-md-12 p-0">
                  <h6>{t("changePassword")}</h6>
                  <div className="form-group">
                    <input
                      className="form-control"
                      placeholder={t("currentPassword")}
                      type="password"
                      name="oldPassword"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.oldPassword || ""}
                      autoComplete="current-password"
                    />
                    {validation.touched.oldPassword &&
                    validation.errors.oldPassword ? (
                      <p style={{ color: "red" }}>
                        {validation.errors.oldPassword}
                      </p>
                    ) : null}
                  </div>

                  <div className="form-group">
                    <input
                      className="form-control"
                      placeholder={t("newPassword")}
                      type={t("password")}
                      name="newPassword"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.newPassword || ""}
                      autoComplete="new-password"
                    />
                    {validation.touched.newPassword &&
                    validation.errors.newPassword ? (
                      <p style={{ color: "red" }}>
                        {validation.errors.newPassword}
                      </p>
                    ) : null}
                  </div>
                  <div className="form-group">
                    <input
                      className="form-control"
                      placeholder={t("confirmNewPassword")}
                      type="password"
                      name="confirmNewPassword"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.confirmNewPassword || ""}
                      autoComplete="new-password"
                    />
                    {validation.touched.confirmNewPassword &&
                    validation.errors.confirmNewPassword ? (
                      <p style={{ color: "red" }}>
                        {validation.errors.confirmNewPassword}
                      </p>
                    ) : null}
                  </div>
                </div>
                <button
                  className="btn btn-primary"
                  style={{
                    backgroundColor: "#fdb813",
                    borderColor: "#fdb813",
                    boxShadow: "none",
                  }}
                  type="submit"
                >
                  {Loading ? (
                    <Circles
                      height="20"
                      width="40"
                      color="white"
                      ariaLabel="circles-loading"
                      wrapperStyle={{}}
                      wrapperClass=""
                      visible={true}
                    />
                  ) : (
                    "Update"
                  )}
                </button>{" "}
                &nbsp;&nbsp;
                {/* <button className="btn btn-default">Cancel</button> */}
              </form>
            </div>

            {/* <div className="body">
              <div className="row">
                <div className="col-lg-6 col-md-12">
                  <h6>Email from Lucid</h6>
                  <p>I'd like to receive the following emails:</p>
                  <ul className="list-unstyled list-email-received">
                    <li>
                      <label className="fancy-checkbox">
                        <input type="checkbox" />
                        <span className="ml-3">Weekly account summary</span>
                      </label>
                    </li>
                    <li>
                      <label className="fancy-checkbox">
                        <input type="checkbox" />
                        <span className="ml-3">Campaign reports</span>
                      </label>
                    </li>
                    <li>
                      <label className="fancy-checkbox">
                        <input type="checkbox" />
                        <span className="ml-3">
                          Promotional news such as offers or discounts
                        </span>
                      </label>
                    </li>
                    <li>
                      <label className="fancy-checkbox">
                        <input type="checkbox" />
                        <span className="ml-3">
                          Tips for campaign setup, growth and client success
                          stories
                        </span>
                      </label>
                    </li>
                  </ul>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default Profile;
