import { createSlice } from "@reduxjs/toolkit";
import {
  CreateProduct,
  findAllProducts,
  getProductById,
  deleteProductById,
  editProduct,
} from "./ProductsActions";
const ProductsSlice = createSlice({
  name: "products",
  initialState: {
    products: null,
    responseData: null,
    error: null,
    success: false,
    Loading: false,
    singleLoading: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(CreateProduct.pending, (state) => {
        // state.Loading = true;
        state.error = null;
      })
      .addCase(CreateProduct.fulfilled, (state, action) => {
        // state.Loading = false;
        state.responseData = action.payload;
      })
      .addCase(CreateProduct.rejected, (state, action) => {
        // state.Loading = false;
        state.error = action.payload;
      })
      .addCase(findAllProducts.pending, (state) => {
        state.Loading = true;
        state.error = null;
      })
      .addCase(findAllProducts.fulfilled, (state, action) => {
        state.Loading = false;
        state.products = action.payload;
      })
      .addCase(findAllProducts.rejected, (state, action) => {
        state.Loading = false;
        state.error = action.payload;
      })
      .addCase(getProductById.pending, (state) => {
        state.singleLoading = true;
        state.error = null;
      })
      .addCase(getProductById.fulfilled, (state, action) => {
        state.singleLoading = false;
        state.responseData = action.payload;
      })
      .addCase(getProductById.rejected, (state, action) => {
        state.singleLoading = false;
        state.error = action.payload;
      })
      .addCase(deleteProductById.pending, (state) => {
        // state.Loading = true;
        state.error = null;
      })
      .addCase(deleteProductById.fulfilled, (state, action) => {
        // state.Loading = false;
        state.responseData = action.payload;
      })
      .addCase(deleteProductById.rejected, (state, action) => {
        // state.Loading = false;
        state.error = action.payload;
      })
      .addCase(editProduct.pending, (state) => {
        // state.Loading = true;
        state.error = null;
      })
      .addCase(editProduct.fulfilled, (state, action) => {
        // state.Loading = false;
        state.responseData = action.payload;
      })
      .addCase(editProduct.rejected, (state, action) => {
        // state.Loading = false;
        state.error = action.payload;
      });
  },
});

export const ProductsAction = ProductsSlice.actions;
export default ProductsSlice;
