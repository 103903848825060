import React, { useState, useEffect } from "react";
import PageTitle from "../../../components/PageTitle";
import { MultiSelect } from "primereact/multiselect";
import { useSelector, useDispatch } from "react-redux";
import {
  getProductById,
  editProduct,
} from "../../../redux/Products/ProductsActions";
import { findAllLines } from "../../../redux/ProductionLines/ProductionLinesActions";
import { findAllComponents } from "../../../redux/Components/ComponentsActions";
import { useFormik } from "formik";
import PagesLoading from "../../../components/Loading/pagesLoading";
import { useTranslation } from "react-i18next";

const ProductEdit = ({ closePopup, id, exist }) => {
  const dispatch = useDispatch();

  const [t] = useTranslation();

  let token = useSelector((state) => state.auth.data?.accessToken);

  const products = useSelector(
    (state) => state.products?.responseData?.product
  );

  const allComponents = useSelector(
    (state) => state.components?.components?.components
  );

  const productionLines = useSelector(
    (state) => state.productionLines?.lines?.productionLines
  );

  const productsLoading = useSelector((state) => state.products?.singleLoading);
  const allComponentsLoading = useSelector(
    (state) => state.components?.Loading
  );
  const productionLinesLoading = useSelector(
    (state) => state.productionLines?.singleLoading
  );

  const [Lines, setLines] = useState([]);
  const [selectedLines, setSelectedLines] = useState([]);
  const [selectedComponent, setSelectedComonent] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [newcomponents, setNewComponents] = useState([]);
  const [previouslyUncheckedLines, setPreviouslyUncheckedLines] = useState([]);

  const [newlyAddedLines, setNewlyAddedLines] = useState([]);
  const [deletedProductionLines, setDeletedProductionLines] = useState([]);
  const [createdComponents, setCreatedComponents] = useState([]);
  const [deletedComponents, setDeletedComponents] = useState([]);

  const newLines = newlyAddedLines?.map((originalObject) => originalObject?.id);
  const deleteLine = deletedProductionLines?.map(
    (originalObject) => originalObject?.id
  );
  const deleteComponent = deletedComponents?.map(
    (originalObject) => originalObject?.id
  );
  const createComponent = createdComponents?.map((originalObject) => ({
    quantity: originalObject?.count,
    id: originalObject?.id,
  }));

  const formik = useFormik({
    initialValues: {
      code: "",
      name: "",
      productionRate: null,
    },
    onSubmit: (values) => {
      dispatch(
        editProduct({
          id,
          code: values.code,
          name: values.name,
          productionRate: values.productionRate,
          createdComponents: createComponent,
          deletedComponents: deleteComponent,
          createdProductionLines: newLines,
          deletedProductionLines: deleteLine,
          token,
        })
      );
      closePopup();
    },
  });

  useEffect(() => {
    let filtered = products?.productsComponents?.map((item) => {
      return selectedOptions?.filter(
        (data) => data?.count !== item?.componentQuantity
      );
    });
    let flattenedAndUnique = [...new Set(filtered?.flat())];
    let filteredComponents = flattenedAndUnique?.filter(
      (obj) =>
        !products?.productsComponents?.some(
          (item) => item?.componentQuantity === obj?.count
        )
    );

    setCreatedComponents(filteredComponents);
  }, [selectedOptions]);

  const linesSelected = products?.productsProductionLines?.map(
    (originalObject) => ({
      name: originalObject.productionLine.name,
      id: originalObject.productionLine.id,
    })
  );

  const componentPerProductOptions = products?.productsComponents?.map(
    (originalObject) => ({
      name: originalObject?.component.name,
      id: originalObject?.componentId,
      count: originalObject?.componentQuantity,
    })
  );
  const LinesData = productionLines?.map((originalObject) => ({
    name: originalObject?.name,
    id: originalObject?.id,
  }));

  const componentPerProduct = products?.productsComponents?.map(
    (originalObject) => ({
      id: originalObject?.componentId,
      name: originalObject?.component.name,
    })
  );

  const allComponentsData = allComponents?.map((originalObject) => ({
    name: originalObject?.name,
    id: originalObject?.id,
  }));

  useEffect(() => {
    dispatch(findAllLines({ token: token }));
    dispatch(findAllComponents({ token: token }));
  }, [token]);

  useEffect(() => {
    if (!exist) {
      dispatch(getProductById({ id: id, token: token }));
    }
    setLines(LinesData);
  }, [productionLines]);
  useEffect(() => {
    if (Lines?.length !== 0) {
      setSelectedLines(linesSelected);
    }
  }, [Lines]);

  useEffect(() => {
    if (allComponents?.length > 0) {
      setSelectedComonent(componentPerProduct);
      setSelectedOptions(componentPerProductOptions);
    }
  }, [allComponents]);

  useEffect(() => {
    const componentsToRemove = selectedOptions?.filter(
      (option) => !selectedComponent.some((comp) => comp?.id === option?.id)
    );

    if (componentsToRemove?.length > 0) {
      const updatedOptions = selectedOptions?.filter(
        (option) => !componentsToRemove.some((comp) => comp?.id === option?.id)
      );

      setSelectedOptions(updatedOptions);
    }
  }, [selectedComponent]);
  const components = allComponentsData;

  const handleCountChange = (e, option) => {
    const count = parseInt(e.target.value, 10);
    const existingOptionIndex = selectedOptions.findIndex(
      (o) => o?.id === option?.id
    );
    const existingComponentIndex = selectedComponent.findIndex(
      (comp) => comp?.id === option?.id
    );

    if (existingComponentIndex !== -1 && count > 0) {
      const updatedOption = { ...option, count };

      if (existingOptionIndex !== -1) {
        const updatedOptions = [...selectedOptions];
        updatedOptions[existingOptionIndex] = updatedOption;
        setSelectedOptions(updatedOptions);
      } else {
        setSelectedOptions([...selectedOptions, updatedOption]);
      }
    } else if (existingOptionIndex !== -1 && existingComponentIndex === -1) {
      const updatedOptions = selectedOptions?.filter(
        (o) => o?.id !== option?.id
      );
      setSelectedOptions(updatedOptions);
    }
  };

  const handleNumberInputClick = (event) => {
    event.stopPropagation();
  };

  const optionTemplate = (option) => {
    const selectedOption = selectedOptions?.find((o) => o?.id === option?.id);
    const count = selectedOption ? selectedOption?.count : 0;
    const isSelected = count > 0;

    return (
      <div
        className="d-flex align-items-center justify-content-between"
        style={{ gap: "10px" }}
      >
        <div>{option.name}</div>
        <input
          className="form-control"
          style={{ width: "60px" }}
          type="number"
          value={count}
          onChange={(e) => handleCountChange(e, option)}
          onClick={handleNumberInputClick}
        />
        {isSelected && <span>&#10003;</span>}
      </div>
    );
  };

  const panelFooterTemplate = () => {
    const length = selectedComponent ? selectedComponent?.length : 0;

    return (
      <div className="py-2 px-3">
        <b>{length}</b> item{length !== 1 ? "s" : ""} selected.
      </div>
    );
  };

  return (
    <>
      {productsLoading && allComponentsLoading && productionLinesLoading ? (
        <PagesLoading />
      ) : (
        <>
          <div className="flex-header">
            <PageTitle Title="Edit Product" />
          </div>
          <hr />
          <div className="">
            <form onSubmit={formik.handleSubmit}>
              <div className="body">
                <div className="row clearfix">
                  <div className="col-lg-6 col-md-12">
                    <div className="form-group">
                      <input
                        name="code"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.code || ""}
                        className="form-control"
                        placeholder={products?.id}
                        type="text"
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12">
                    <div className="form-group">
                      <input
                        name="name"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.name || ""}
                        className="form-control"
                        placeholder={products?.name}
                        type="text"
                      />
                    </div>
                  </div>

                  <div className="col-lg-6 col-md-12">
                    <div className="form-group">
                      <input
                        name="productionRate"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.productionRate || null}
                        className="form-control"
                        placeholder={
                          t("productionRate") + " " + products?.productionRate
                        }
                        type="number"
                        min="0"
                        onClick={handleNumberInputClick}
                      />
                    </div>
                  </div>

                  <div className="col-lg-6 col-md-12 ">
                    <MultiSelect
                      value={selectedLines}
                      onChange={(e) => {
                        setSelectedLines(e.value);
                        const uncheckedLines = linesSelected?.filter(
                          (line) =>
                            !e.value.some(
                              (selectedLine) => selectedLine?.id === line?.id
                            )
                        );
                        const checkedLines = e.value?.filter(
                          (selectedLine) =>
                            !linesSelected.some(
                              (line) => line?.id === selectedLine?.id
                            )
                        );
                        const newDeletedLines = deletedProductionLines?.filter(
                          (deletedLine) =>
                            !previouslyUncheckedLines.includes(deletedLine?.id)
                        );

                        setDeletedProductionLines([
                          ...newDeletedLines,
                          ...uncheckedLines,
                        ]);

                        setPreviouslyUncheckedLines(
                          uncheckedLines.map((line) => line?.id)
                        );

                        setNewlyAddedLines(checkedLines);
                      }}
                      options={Lines}
                      checked
                      optionLabel="name"
                      filter
                      placeholder={t("selectLines")}
                      maxSelectedLabels={3}
                      style={{ width: "100%" }}
                    />
                  </div>

                  <div className="col-lg-6 col-md-12 ">
                    <MultiSelect
                      value={selectedComponent}
                      options={components}
                      onChange={(e) => {
                        const newSelectedComponents = e.value;

                        const originalProductComponents =
                          componentPerProduct.map(
                            (productComp) => productComp?.id
                          );

                        const newlyAdded = newSelectedComponents?.filter(
                          (selectedComp) =>
                            !originalProductComponents.includes(
                              selectedComp?.id
                            )
                        );

                        const componentsToAdd = newlyAdded?.filter(
                          (comp) =>
                            !newcomponents.some(
                              (newComp) => newComp?.id === comp?.id
                            )
                        );

                        const updatedNewComponents = [
                          ...newcomponents,
                          ...componentsToAdd,
                        ];

                        const newlyUnselectedComponents =
                          selectedComponent?.filter(
                            (comp) => !newSelectedComponents.includes(comp)
                          );
                        const deletedComps = newlyUnselectedComponents?.filter(
                          (comp) =>
                            componentPerProduct.some(
                              (originalComp) => originalComp?.id === comp?.id
                            )
                        );

                        setDeletedComponents((prevDeleted) => [
                          ...prevDeleted,
                          ...deletedComps,
                        ]);
                        setDeletedComponents((prevDeleted) =>
                          prevDeleted?.filter((deletedComp) =>
                            newSelectedComponents.every(
                              (selectedComp) =>
                                selectedComp?.id !== deletedComp?.id
                            )
                          )
                        );
                        setNewComponents(updatedNewComponents);

                        setSelectedComonent(newSelectedComponents);
                      }}
                      optionLabel="name"
                      placeholder={t("selectComponents")}
                      itemTemplate={optionTemplate}
                      panelFooterTemplate={panelFooterTemplate}
                      maxSelectedLabels={3}
                      display="chip"
                      style={{ width: "100%" }}
                      filter
                    />
                  </div>
                </div>
              </div>
              <div className="d-flex justify-content-center btn-flex pr-3 my-3">
                <button
                  className="btn  mr-5 style-btn color-btn"
                  type="button"
                  onClick={() => closePopup()}
                >
                  {t("cancel")}
                </button>
                <button className="btn  style-btn coloring" type="submit">
                  {t("editProduct")}
                </button>
              </div>
            </form>
          </div>
        </>
      )}
    </>
  );
};

export default ProductEdit;
