import React, { useEffect, useRef, useState } from "react";
import Chart from "chart.js/auto";
import zoomPlugin from "chartjs-plugin-zoom";
import { Button } from "primereact/button";
// import 'index.scss';
// import "chartjs-adapter-moment";
Chart.register(zoomPlugin);

const randomInt = () => Math.floor(Math.random() * (10 - 1 + 1)) + 1;

const chartConfig = {
  type: "bar",
  data: {
    labels: ["January", "February", "March", "April", "May", "June", "July","August","September","October","November","December"],
    datasets: [
      {
        label: "planned",
        data: [65, 59, 80, 81, 56, 55, 40,33,43,77],
        fill: false,
        borderColor: "rgba(160, 160,160, 1)",
        backgroundColor: "rgba(160, 160,160, 1)",
        tension: 0.1,
        stack: "stack1",
      },
      {
        label: "In Preparation",
        data: [65, 59, 80, 81, 56, 55, 40],
        fill: false,
        borderColor: "rgba(95, 172,255, 1)",
        backgroundColor: "rgba(95, 172,255, 1)",
        tension: 0.1,
        stack: "stack1",
      },
      {
        label: "Ready",
        data: [65, 59, 80, 81, 56, 55, 40,44,55,22,4],
        fill: false,
        borderColor: "rgba(110, 196,210, 1)",
        backgroundColor: "rgba(110, 196,210, 1)",
        tension: 0.1,
        stack: "stack1",
      },
      {
        label: "On Train",
        data: [65, 59, 80, 81, 56, 55, 40,55,66,88,99,77],
        fill: false,
        borderColor: "rgba(255, 216,100, 1)",
        backgroundColor: "rgba(255, 216,100, 1)",
        tension: 0.1,
        stack: "stack1",
      },
      {
        label: "Delivered",
        data: [65, 59, 80, 81, 56, 55, 40],
        fill: false,
        borderColor: "rgba(66, 178,92, 1)",
        backgroundColor: "rgba(66, 178,92, 1)",
        tension: 0.1,
        stack: "stack1",
      },
      {
        label: "Canceled",
        data: [65, 59, 80, 81, 56, 55, 40],
        fill: false,
        borderColor: "rgba(231, 114,125, 1)",
        backgroundColor: "rgba(231, 114,125, 1)",
        tension: 0.1,
        stack: "stack1",
      },
    ],
  },
  options: {
    responsive: true,
    // maintainAspectRatio: false,
    plugins: {
      zoom: {
        zoom: {
          wheel: {
            enabled: true,
          },
          mode: "x",
          speed: 100,
        },
        pan: {
          enabled: true,
          mode: "x",
          speed: 0.5,
        },
      },
    },
  },
};

const ZoomableBarChart = () => {
  const chartContainer = useRef(null);
  const [chartInstance, setChartInstance] = useState(null);

  useEffect(() => {
    if (chartContainer && chartContainer.current) {
      const newChartInstance = new Chart(chartContainer.current, chartConfig);
      setChartInstance(newChartInstance);
      return () => {
        newChartInstance.destroy();
      };
    }
  }, [chartContainer]);

  const updateDataset = (datasetIndex, newData) => {
    chartInstance.data.datasets[datasetIndex].data = newData;
    chartInstance.update();
  };

  const resetZoom = () => {
    // Check if the chart instance exists and the zoom plugin is enabled
    if (chartInstance && chartInstance.options.plugins.zoom) {
      chartInstance.resetZoom();
    }
  };

  return (
    <div>
      <div className="card" style={{ height: "500px", padding: "15px" }}>
        <div
          style={{ display: "flex" }}
          className="justify-content-between py-3"
        >
          <Button
            label="Reset"
            severity="warning"
            size="small"
            onClick={resetZoom}
          />
          <span className="p-buttonset">
            <Button label="D" size="small" icon="pi pi-check" />
            <Button label="W" size="small" icon="pi pi-trash" />
            <Button label="M" size="small" icon="pi pi-times" />
            <Button label="Y" size="small" icon="pi pi-times" />
          </span>
        </div>

        <canvas ref={chartContainer} />
        {/* <button className="btn  style-btn coloring my-2" type="submit">
          Reset
        </button> */}
      </div>
    </div>
  );
};
export default ZoomableBarChart;
