import React, { useState, useEffect } from "react";
import PageTitle from "../../components/PageTitle";
import BackButton from "../../components/Buttons/backButton";
import TableList from "../../components/TableList";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getAllOrders } from "../../redux/Orders/OrdersActions";
import convertTimestampToDateTime from "../../hooks/timeStampConverter";
import NewRequest from "../NewRequest";
import PopUp from "../../components/PopUp";
import DeleteOrder from "./deleteOrder";
import PagesLoading from "../../components/Loading/pagesLoading";
import setTitle from "../../hooks/Title";
import { badgeOrderStatus, badgePriorty } from "../../hooks/generic";
import { Calendar } from "primereact/calendar";
import formatDate from "../../hooks/dateFormatter";
import TablePagination from "../../components/Pagination";
import { useTranslation } from "react-i18next";

import { FilterMatchMode, FilterOperator } from "primereact/api";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import socket from "../../utils/webSoket/webSocket";

const ProductionPlan = () => {
  const dispatch = useDispatch();

  const [t] = useTranslation();

  let token = useSelector((state) => state.auth.data?.accessToken);
  const responseData = useSelector((state) => state.orders?.orders);
  const Loading = useSelector((state) => state.orders.Loading);

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(14);

  const [currentItems, setCurrentItems] = useState([]);
  const [isVisible, setIsVisible] = useState(false);
  const [isDeleteVisible, setIsDeleteVisible] = useState(false);
  const [userId, setUserId] = useState("");

  const [dates, setDates] = useState(null);
  const [productName, setProductName] = useState("");
  const [productionLineName, setProductionLineName] = useState("");
  const [status, setStatus] = useState("");

  const [dataInfo, setDataInfo] = useState(null);
  const [filters, setFilters] = useState(null);

  const initFilters = () => {
    setFilters({
      status: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
      },
      date: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
      },
      name: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
      },
      line: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
      },
    });
  };

  useEffect(() => {
    setDataInfo(currentItems);
    initFilters();
  }, [currentItems]);

  socket.on("created_orders", (info) => {
    const updatedCurrentItems = [info, ...currentItems];
    setCurrentItems(updatedCurrentItems);
    console.log(info);
  });

  socket.on("updated_orders", (info) => {
    setCurrentItems(
      currentItems.map((obj) => {
        if (obj.id === info.id) {
          return { ...info }; // Replace the entire object with a new one
        }
        return obj;
      })
    );
    console.log("edit", info);
  });

  const statusBody = (rowData) => {
    return <>{badgeOrderStatus(rowData?.status, t)}</>;
  };
  const idBody = (rowData) => {
    const { dateVariable, timeVariable } = convertTimestampToDateTime(
      rowData?.createdAt
    );
    return (
      <>
        {" "}
        <h6>
          <Link>{rowData.id}</Link>
        </h6>
        <small>Created {dateVariable}</small>
      </>
    );
  };
  const timeBody = (rowData) => {
    const { dateVariable, timeVariable } = convertTimestampToDateTime(
      rowData?.createdAt
    );
    return (
      <>
        <h6>{timeVariable}</h6>
      </>
    );
  };

  const lineBody = (rowData) => {
    return (
      <>
        <h6>{rowData?.productionLine?.name}</h6>
      </>
    );
  };
  const productBody = (rowData) => {
    return (
      <>
        <h6>{rowData?.product?.name}</h6>
      </>
    );
  };

  const prorityBody = (rowData) => {
    return <>{badgePriorty("High")}</>;
  };

  const elementAction = (rowData) => {
    return (
      <>
        {rowData?.status !== "canceled" ? (
          <div
            className="btn btn-outline-danger danger "
            onClick={() => handleDeleteUser(rowData.id)}
          >
            <i className="fa fa-times"></i>
          </div>
        ) : (
          ""
        )}
      </>
    );
  };

  const statusFilterTemplate = () => {
    return (
      <div>
        <select
          name="status"
          defaultValue="status"
          className="form-control"
          onChange={(e) => setStatus(e.target.value)}
        >
          <option disabled value="status">
            status
          </option>
          <option value="planned">Planned</option>
          <option value="in_preparation">In preparation</option>
          <option value="ready">Ready</option>
          <option value="on_train">On Train</option>
          <option value="delivered">Delevered</option>
          <option value="canceled">Canceled</option>
        </select>
      </div>
    );
  };
  const dateFilterTemplate = () => {
    return (
      <div>
        <div className="input-group">
          <Calendar
            value={dates}
            onChange={(e) => setDates(e.value)}
            selectionMode="range"
            readOnlyInput
            placeholder="Date "
          />
        </div>
      </div>
    );
  };

  const nameFilterTemplate = () => {
    return (
      <div>
        <input
          className="form-control"
          placeholder="Product Name"
          type="text"
          onChange={(e) => setProductName(e.target.value)}
        />
      </div>
    );
  };

  const lineFilterTemplate = () => {
    return (
      <div>
        <input
          className="form-control"
          placeholder="Line"
          type="text"
          onChange={(e) => setProductionLineName(e.target.value)}
        />
      </div>
    );
  };
  const renderHeader = () => {
    return (
      <div className="flex justify-content-between">
        <Button
          type="button"
          label="Clear"
          outlined
          style={{
            padding: "4px 8px",
            color: "black",
            opacity: "0.6",
          }}
          onClick={() => {
            dispatch(
              getAllOrders({
                token: token,
                page: currentPage,
                limit: itemsPerPage,
              })
            );
          }}
        />
      </div>
    );
  };

  const headers = renderHeader();
  const closeDeletehandle = () => {
    setIsDeleteVisible(false);
  };

  const handleDeleteUser = (userId) => {
    setUserId(userId);
    setIsDeleteVisible(true);
  };

  useEffect(() => {
    setTitle("Production PLan");

    if (dates) {
      dispatch(
        getAllOrders({
          token: token,
          page: currentPage,
          limit: itemsPerPage,
          dates,
          productName,
          productionLineName,
          status,
          startDate: formatDate(dates[0]),
          endDate: formatDate(dates[1]),
        })
      );
    } else {
      dispatch(
        getAllOrders({
          token: token,
          page: currentPage,
          limit: itemsPerPage,
          dates,
          productName,
          productionLineName,
          status,
        })
      );
    }
  }, [
    dispatch,
    token,
    currentPage,
    itemsPerPage,
    dates,
    productName,
    productionLineName,
    status,
  ]);

  const visibleHandel = () => {
    setIsVisible(true);
  };
  const closehandle = () => {
    setIsVisible(false);
    setTimeout(() => {
      dispatch(getAllOrders({ token: token }));
    }, 3000);
  };
  const header = [
    t("status"),
    t("id"),
    t("time"),
    t("productName"),
    t("qty"),
    t("line"),
    t("priority"),
    t("actions"),
  ];
  let filteredData = (dataT) => {
    setCurrentItems(dataT);
  };

  return (
    <>
      <PopUp
        text={<NewRequest closePopup={closehandle} />}
        isVisible={isVisible}
      />
      <PopUp
        text={<DeleteOrder closePopup={closeDeletehandle} id={userId} />}
        isVisible={isDeleteVisible}
      />
      <div id="main-content">
        <BackButton />

        <div className="flex-header">
          <PageTitle Title={t("productionPlan")} />
          <div className="btn-style">
            <Link className="btn btn-outline-secondary" onClick={visibleHandel}>
              <i className="icon-plus plus"></i>
            </Link>
          </div>
        </div>

        <div className="table-list">
          <div className="table-responsive">
            <DataTable
              value={dataInfo}
              loading={Loading}
              loadingOverlay={null}
              loadingIcon={<PagesLoading />}
              dataKey="id"
              filters={filters}
              header={headers}
              emptyMessage="No data found."
            >
              <Column
                header={t("status")}
                field="status"
                filter
                filterElement={statusFilterTemplate}
                style={{ minWidth: "9rem" }}
                showAddButton={false}
                showApplyButton={false}
                showClearButton={false}
                showFilterMenuOptions={false}
                body={statusBody}
              />
              <Column
                header={t("id")}
                field="date"
                filter
                filterElement={dateFilterTemplate}
                style={{ minWidth: "9rem" }}
                showAddButton={false}
                showApplyButton={false}
                showClearButton={false}
                showFilterMenuOptions={false}
                body={idBody}
              />
              <Column
                header={t("time")}
                field="Status"
                style={{ minWidth: "9rem" }}
                body={timeBody}
              />
              <Column
                header={t("productName")}
                field="name"
                filter
                filterElement={nameFilterTemplate}
                style={{ minWidth: "9rem" }}
                showAddButton={false}
                showApplyButton={false}
                showClearButton={false}
                showFilterMenuOptions={false}
                body={productBody}
              />
              <Column
                header={t("qty")}
                field="productQuantity"
                style={{ minWidth: "9rem" }}
              />
              <Column
                header={t("line")}
                field="line"
                filter
                filterElement={lineFilterTemplate}
                style={{ minWidth: "9rem" }}
                showAddButton={false}
                showApplyButton={false}
                showClearButton={false}
                showFilterMenuOptions={false}
                body={lineBody}
              />
              <Column
                header={t("priority")}
                style={{ minWidth: "9rem" }}
                body={prorityBody}
              />

              <Column
                header={t("actions")}
                field="code"
                style={{ minWidth: "9rem" }}
                body={elementAction}
              />
            </DataTable>
          </div>
        </div>
        <TablePagination
          data={responseData?.components}
          filterData={filteredData}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          responseData={responseData}
        />
      </div>
    </>
  );
};

export default ProductionPlan;
