import UserImage from "../../assets/images/user.jpg";
import { Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { authAction } from "../../redux/Auth/AuthSlice";
import { useTranslation } from "react-i18next";

const UserAccount = () => {
  const dispatch = useDispatch();
  const [t] = useTranslation();

  const user = useSelector((state) => state.auth?.data?.user);

  return (
    <>
      <div className="user-account">
        <img
          src={UserImage}
          className="rounded-circle user-photo"
          alt="User Profile Picture"
        />
        <Dropdown>
          <span>{t("welcome")}</span>
          <Dropdown.Toggle
            variant="none"
            as="a"
            id="dropdown-basic"
            className="user-name"
          >
            <strong>{user.displayName}</strong>
          </Dropdown.Toggle>
          <div className="dropdown">
            <Dropdown.Menu className="dropdown-menu-right account">
              <Dropdown.Item as={Link} to="/profile">
                <i className="icon-user"></i>
                {t("myProfile")}
              </Dropdown.Item>

              <li className="divider"></li>
              <Dropdown.Item
                as={Link}
                to="/login"
                onClick={() => {
                  dispatch(authAction.logOut());
                }}
              >
                <i className="icon-power"></i>
                {t("logout")}
              </Dropdown.Item>
            </Dropdown.Menu>
          </div>
        </Dropdown>
        <h5 className="text-center company-title">{user.account.name}</h5>
        <hr />
      </div>
    </>
  );
};

export default UserAccount;
