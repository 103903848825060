import React, { useState } from "react";
import Dropzone from "react-dropzone";
// import ProgressBar from "react-progress-bar";
import s3 from "../../utils/aws/aws";
const UploadImageToS3 = () => {
  const [file, setFile] = useState(null);
  const [progress, setProgress] = useState(0);


  const handleDrop = (acceptedFiles) => {
    const selectedFile = acceptedFiles[0];
    setFile(selectedFile);

    const params = {
      Bucket: process.env.REACT_APP_AWS_BUCKET_NAME,
      Key: selectedFile.name,
      Body: selectedFile,
    };

    const upload = s3.upload(params);

    upload.on("httpUploadProgress", (event) => {
      const percentCompleted = Math.round((event.loaded / event.total) * 100);
      setProgress(percentCompleted);
    });

    upload.send((err, data) => {
      if (err) {
        console.error("Error uploading image:", err);
      } else {
        console.log("Image uploaded successfully:", data.Key);
        // You can store the S3 URL (data.Location) in your database or use it as needed.
        setFile(null);
        setProgress(0);
      }
    });
  };

  return (
    <div>
      <Dropzone onDrop={handleDrop} accept="image/*">
        {({ getRootProps, getInputProps }) => (
          <div {...getRootProps()} className="dropzone">
            <input {...getInputProps()} />
            <p>Drag & drop an image here or click to select one.</p>
          </div>
        )}
      </Dropzone>
      {file && (
        <div>
          <h4>Uploading: {file.name}</h4>
          {/* <ProgressBar completed={progress} bgColor="#00bfff" height="20px" /> */}
        </div>
      )}
    </div>
  );
};

export default UploadImageToS3;
