import React, { useEffect, useState } from "react";
import PageTitle from "../../components/PageTitle";
import SparkleCard from "./Charts/SparkleCard";
import PopUp from "../../components/PopUp/index";
import "echarts-gl";
import DataManaged from "./DataManaged/index";
import TopProcucts from "./Charts/TopProudcts";
import { useSelector, useDispatch } from "react-redux";
import Driver from "../Driver";
import PreparationRequests from "../PreparationRequests";
import Station from "../Station";
import { dashboardStatus } from "../../redux/Dashboard/DashboardActions";
import PagesLoading from "../../components/Loading/pagesLoading";
import ZoomableBarChart from "./Charts/ZoomableBarChart/ZoomableBarChart";
import Loading from "../../components/Loading/index";
import { Calendar } from "primereact/calendar";

const Dashboard = () => {
  const dispatch = useDispatch();
  let token = useSelector((state) => state.auth.data?.accessToken);
  let response = useSelector((state) => state.dashboard.responseData);
  const loading = useSelector((state) => state.dashboard?.Loading);

  const currentDate = new Date();
  const previousMonth = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth() - 1
  );

  const [date, setDate] = useState([previousMonth, currentDate]);

  const [data, setData] = useState([]);
  useEffect(() => {
    dispatch(
      dashboardStatus({
        token: token,
      })
    );
  }, [token]);
  console.log("data", response);
  useEffect(() => {
    if (response != null) {
      setData(response);
    }
  }, [response]);

  const sparkleCardData = [
    {
      heading: "Planned",
      money: data?.dashboard?.planned,
      sparklineData: {
        type: "line",
        data: [1, 4, 1, 3, 7, 1],
        areaStyle: {
          color: "rgba(160, 160,160, 1)",
        },
        symbolSize: 1,
      },
    },
    {
      heading: "In Preparation",
      money: data?.dashboard?.inPreparation,
      sparklineData: {
        type: "line",
        data: [1, 4, 2, 3, 6, 2],
        areaStyle: {
          color: "rgba(95, 172,255, 1)",
        },

        symbolSize: 1,
      },
    },
    {
      heading: "Ready",
      money: data?.dashboard?.ready,
      sparklineData: {
        type: "line",
        data: [1, 4, 2, 3, 1, 5],
        areaStyle: {
          color: "rgba(110, 196,210, 1)",
        },

        symbolSize: 1,
      },
    },
    {
      heading: "On Train",
      money: data?.dashboard?.onTrain,
      sparklineData: {
        type: "line",
        data: [1, 3, 5, 1, 4, 2],
        areaStyle: {
          color: "rgba(255, 216,100, 1)",
        },

        symbolSize: 1,
      },
    },
    {
      heading: "Deliverd",
      money: data?.dashboard?.delivered,
      sparklineData: {
        type: "line",
        data: [1, 3, 5, 1, 4, 2],
        areaStyle: {
          color: "rgba(66, 178,92, 1)",
        },

        symbolSize: 1,
      },
    },
    {
      heading: "Canceled",
      money: data?.dashboard?.canceled,
      sparklineData: {
        type: "line",
        data: [1, 3, 5, 1, 4, 2],
        areaStyle: {
          color: "rgba(231, 114,125, 1)",
        },

        symbolSize: 1,
      },
    },
  ];

  const [cardData, setCardData] = useState([]);
  const role = useSelector((state) => state.auth.data?.role);
  useEffect(() => {
    setCardData([...sparkleCardData]);
  }, []);

  const DashboardRole = (role) => {
    switch (role) {
      case "super_admin'":
        return null;

        break;
      case "client_admin":
        return (
          <div id="main-content">
            <div className="d-flex align-items-center justify-content-between">
              <PageTitle Title="Dashboard" />
              <Calendar
                value={date}
                onChange={(e) => setDate(e.value)}
                dateFormat="yy-mm-dd"
                placeholder="Select Date Range"
                mask="99/99/99"
                selectionMode="range"
                readOnlyInput
                // showIcon
                showButtonBar
                clearButtonClassName="calender-clear-btn"
                className="mb-3"
              />
            </div>
            <div className="row clearfix">
              {cardData.map((data, i) => (
                <SparkleCard
                  index={i}
                  key={data.heading}
                  Heading={data.heading}
                  Money={data.money}
                  isRandomUpdate={true}
                  mainData={data.sparklineData.data}
                  chartColor={data.sparklineData.areaStyle.color}
                  ContainerClass="col-lg-4 col-md-6 col-sm-6"
                />
              ))}
            </div>

            <div className="row">
              <div className="col-12 col-lg-7">
                <ZoomableBarChart />
                {/* <TopProcucts /> */}
              </div>
              <div className="col-12 col-lg-5">
                <DataManaged />
              </div>
            </div>

            <PopUp text={"Hello Orthoplex "} />
          </div>
        );
        break;
      case "preparation_operator":
        return (
          <PreparationRequests
            planned={data?.dashboard?.planned}
            inPreparation={data?.dashboard?.inPreparation}
            ready={data?.dashboard?.ready}
          />
        );
        break;
      case "driver":
        return (
          <Driver
            ready={data?.dashboard?.ready}
            onTrain={data?.dashboard?.onTrain}
            delivered={data?.dashboard?.delivered}
          />
        );

        break;
      case "delivery_station":
        return <Station />;

        break;
      default:
        return null;
        break;
    }
  };

  return <>{loading ? <PagesLoading /> : <>{DashboardRole(role)}</>}</>;
};

export default Dashboard;
