// SuccessPage component
import React, { useEffect } from "react";
import "./index.scss";

const SuccessPage = ({ isVisible, onClose }) => {
  useEffect(() => {
    if (isVisible) {
      document.documentElement.style.overflow = "hidden";
    } else {
      document.documentElement.style.overflow = "auto";
    }
  }, [isVisible]);

  const handleOverlayClick = () => {
    // Call the onClose callback when the overlay is clicked
    onClose();
  };

  return (
    <>
      {isVisible && (
        <div
          className="overlay"
          style={{ zIndex: "1000" }}
          onClick={handleOverlayClick}
        >
          <div className="popup-submit">
            <div class="main-container">
              <div class="check-container">
                <div class="check-background">
                  <svg
                    viewBox="0 0 65 51"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M7 25L27.3077 44L58.5 7"
                      stroke="white"
                      stroke-width="13"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-center btn-flex pr-3 my-3">
              <button
                className="btn  mr-5 style-btn-style color-btn"
                type="button"
                // onClick={() => closePopup()}
              >
                Save & Close
              </button>
              <button className="btn  style-btn-style coloring-success" type="submit">
                Save & New
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default SuccessPage;
