import React, { useState, useEffect } from "react";
import PageTitle from "../../components/PageTitle";
import TableList from "../../components/TableList";
import { Link } from "react-router-dom";
import TablePagination from "../../components/Pagination";
import PopUp from "../../components/PopUp";
import ComponentsAdd from "./ComponentsAdd";
import ComponentsEdit from "./ComponentsEdits";
import BackButton from "../../components/Buttons/backButton";
import UploadFile from "../../components/uploudFile";
import { Dropdown } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { findAllComponents } from "../../redux/Components/ComponentsActions";
import setTitle from "../../hooks/Title";
import DeleteComponent from "./deleteComponent";
import PagesLoading from "../../components/Loading/pagesLoading";
import NoData from "../../components/NoData";
import socket from "../../utils/webSoket/webSocket";
import { useTranslation } from "react-i18next";

import { FilterMatchMode, FilterOperator } from "primereact/api";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";

const Components = () => {
  const dispatch = useDispatch();

  let token = useSelector((state) => state.auth?.data?.accessToken);
  const responseData = useSelector((state) => state.components?.components);
  const loading = useSelector((state) => state.components?.Loading);

  const [data, setData] = useState([]);
  const [filterVisabilty, setFilterVisabilty] = useState(false);
  const [currentItems, setCurrentItems] = useState([]);
  const [isVisible, setIsVisible] = useState(false);
  const [isEditVisible, setIsEditVisible] = useState(false);
  const [isDeleteVisible, setIsDeleteVisible] = useState(false);
  const [isFileVisible, setIsFileVisible] = useState(false);
  const [componentId, setComponentId] = useState("");

  // Filter State
  const [code, setCode] = useState("");
  const [name, setName] = useState("");
  const [packageType, setPackageType] = useState("");

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(14);

  const [dataInfo, setDataInfo] = useState(null);
  const [filters, setFilters] = useState(null);

  const [t] = useTranslation();

  const initFilters = () => {
    setFilters({
      code: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
      },
      name: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
      },
      packageType: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
      },
    });
  };
  useEffect(() => {
    setDataInfo(currentItems);
    initFilters();
  }, [currentItems]);

  const codeFilterTemplate = () => {
    return (
      <div className="">
        <input
          className="form-control"
          placeholder="code "
          type="number"
          onChange={(e) => setCode(e.target.value)}
        />
      </div>
    );
  };

  const nameFilterTemplate = () => {
    return (
      <div className="">
        <input
          className="form-control"
          placeholder="Description"
          type="text"
          onChange={(e) => setName(e.target.value)}
        />
      </div>
    );
  };
  const packageTypeFilterTemplate = () => {
    return (
      <div className="">
        <select
          name="packagingType"
          defaultValue="packagingType"
          className="form-control"
          onChange={(e) => setPackageType(e.target.value)}
        >
          <option disabled value="packagingType">
            packagingType
          </option>
          <option value="box">Box</option>
        </select>
      </div>
    );
  };

  const elementAction = (rowData) => {
    return (
      <div className="project-actions">
        <Link
          to={`/admin/components/${rowData.id}`}
          className="btn btn-outline-secondary mr-1"
        >
          <i className="icon-eye"></i>
        </Link>
        <div
          className="btn btn-outline-secondary"
          onClick={() => visibleEditHandel(rowData.id)}
        >
          <i className="icon-pencil"></i>
        </div>
        <div
          className="btn btn-outline-danger danger "
          onClick={() => handleDeleteComponent(rowData.id)}
        >
          <i className="icon-trash"></i>
        </div>
      </div>
    );
  };
  const renderHeader = () => {
    return (
      <div className="flex justify-content-between">
        <Button
          type="button"
          label="Clear"
          outlined
          style={{
            padding: "4px 8px",
            color: "black",
            opacity: "0.6",
          }}
          disabled={false}
          onClick={() => {
            dispatch(
              findAllComponents({
                token: token,
                page: currentPage,
                limit: itemsPerPage,
              })
            );
          }}
        />
      </div>
    );
  };

  const headers = renderHeader();

  socket.on("created_components", (info) => {
    const updatedCurrentItems = [info, ...currentItems];
    setCurrentItems(updatedCurrentItems);
  });

  socket.on("updated_components", (info) => {
    setCurrentItems(
      currentItems.map((obj) => {
        if (obj.id === info.id) {
          return { ...info };
        }
        return obj;
      })
    );
  });

  socket.on("deleted_components", (info) => {
    const filtered = currentItems.filter((item) => item.id !== info.id);
    setCurrentItems(filtered);
  });

  useEffect(() => {
    if (responseData) {
      setData(responseData);
    }
  }, [responseData]);
  useEffect(() => {
    setTitle("Components");
    dispatch(
      findAllComponents({
        token: token,
        page: currentPage,
        limit: itemsPerPage,
        code,
        name,
        packageType,
      })
    );
  }, [token, currentPage, itemsPerPage, code, name, packageType]);

  const visibleHandel = () => {
    setIsVisible(true);
  };
  const visibleEditHandel = (componentId) => {
    setIsEditVisible(true);
    setComponentId(componentId);
  };
  const closeEdithandle = () => {
    setIsEditVisible(false);
  };
  const closehandle = () => {
    setIsVisible(false);
  };

  const closeDeletehandle = () => {
    setIsDeleteVisible(false);
  };

  const handleDeleteComponent = (componentId) => {
    setComponentId(componentId);
    setIsDeleteVisible(true);
  };

  let filteredData = (dataT) => {
    setCurrentItems(dataT);
  };
  const visibleFileHandel = () => {
    setIsFileVisible(true);
  };
  const closeFilehandle = () => {
    setIsFileVisible(false);
  };

  let content;

  content = (
    <>
      <div className="table-list">
        <div className="table-responsive">
          <DataTable
            value={dataInfo}
            loading={loading}
            loadingOverlay={null}
            loadingIcon={<PagesLoading />}
            dataKey="id"
            filters={filters}
            header={headers}
            emptyMessage={t("noDataFound")}
          >
            <Column
              header={t("code")}
              field="code"
              filter
              filterElement={codeFilterTemplate}
              style={{ minWidth: "9rem" }}
              showAddButton={false}
              showApplyButton={false}
              showClearButton={false}
              showFilterMenuOptions={false}
            />
            <Column
              header={t("description")}
              field="name"
              filter
              filterElement={nameFilterTemplate}
              style={{ minWidth: "9rem" }}
              showAddButton={false}
              showApplyButton={false}
              showClearButton={false}
              showFilterMenuOptions={false}
            />
            <Column
              header={t("packaging-type")}
              field="packageType"
              filter
              filterElement={packageTypeFilterTemplate}
              style={{ minWidth: "9rem" }}
              showAddButton={false}
              showApplyButton={false}
              showClearButton={false}
              showFilterMenuOptions={false}
            />
            <Column
              header={t("qty-per-packaging")}
              field="quantityPerPackage"
              style={{ minWidth: "9rem" }}
            />
            <Column
              header={t("actions")}
              field="code"
              style={{ minWidth: "12rem" }}
              body={elementAction}
            />
          </DataTable>
        </div>
      </div>
      <TablePagination
        data={data?.components}
        filterData={filteredData}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        responseData={data}
      />
    </>
  );

  return (
    <>
      <PopUp
        text={
          <DeleteComponent closePopup={closeDeletehandle} id={componentId} />
        }
        isVisible={isDeleteVisible}
      />
      <PopUp
        text={<ComponentsEdit closePopup={closeEdithandle} id={componentId} />}
        isVisible={isEditVisible}
      />
      <PopUp
        text={<ComponentsAdd closePopup={closehandle} />}
        isVisible={isVisible}
      />
      <PopUp
        text={<UploadFile closePopup={closeFilehandle} />}
        isVisible={isFileVisible}
      />
      <div id="main-content">
        <div className="flex-header">
          <PageTitle Title={t("components")} />

          <div className="btn-style">
            <div className="user-account m-0">
              <Dropdown className="m-0">
                <Dropdown.Toggle variant="none" as="a" id="dropdown-basic">
                  <div className="btn btn-outline-secondary">
                    <i className="icon-plus plus"></i>
                  </div>
                </Dropdown.Toggle>
                <div className="dropdown">
                  <Dropdown.Menu className="dropdown-menu-right account">
                    <Dropdown.Item onClick={visibleFileHandel}>
                      <i className="fa fa-file-o"></i> {t("uploadeFile")}
                    </Dropdown.Item>

                    <li className="divider"></li>
                    <Dropdown.Item onClick={visibleHandel}>
                      <i className="fa fa-database"></i>
                      {t("addComponent")}
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </div>
              </Dropdown>
            </div>
          </div>
        </div>

        {content}
      </div>
    </>
  );
};

export default Components;
