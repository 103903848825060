import React from "react";
import { Link } from "react-router-dom";
// import withAuth from "../../hooks/withAuth";

import { useState, useEffect } from "react";
import { Dropdown } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
// import { findAllProducts } from "../../../redux/Products/ProductsActions";
import { Calendar } from "primereact/calendar";
import TableList from "../../../components/TableList";
import TablePagination from "../../../components/Pagination";
import BackButton from "../../../components/Buttons/backButton";
import UploadFile from "../../../components/uploudFile";
import setTitle from "../../../hooks/Title";
import PageTitle from "../../../components/PageTitle";
import PopUp from "../../../components/PopUp";
// import ProductsAdd from "./ProductsAdd";
// import ProductEdit from "./ProuductsEdits";
// import DeleteProduct from "./deleteProduct";
import DefectImage from "./DefectImage";
import PagesLoading from "../../../components/Loading/pagesLoading";

import { useTranslation } from "react-i18next";

const Defects = () => {
  const dispatch = useDispatch();
  let token = useSelector((state) => state.auth.data?.accessToken);
  const responseData = useSelector((state) => state.products.products);
  const loading = useSelector((state) => state.products.Loading);

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(14);
  const [filterVisabilty, setFilterVisabilty] = useState(false);
  const [currentItems, setCurrentItems] = useState([]);
  const [isVisible, setIsVisible] = useState(false);
  const [isEditVisible, setIsEditVisible] = useState(false);
  const [isFileVisible, setIsFileVisible] = useState(false);
  const [isDeleteVisible, setIsDeleteVisible] = useState(false);
  const [productId, setProductId] = useState("");

  // Filter State
  const [productionLine, setProductionLine] = useState("");
  const [product, setProduct] = useState("");
  const [Component, setComponent] = useState("");
  const [Defect, setDefect] = useState("");
  const [Category, setCategory] = useState("");
  const [User, setUser] = useState("");
  const [dateTime, setDateTime] = useState("");

  const [t] = useTranslation();

  const closeImagehandle = () => {
    setIsVisible(true);
  };
  // const closehandle = () => {
  //   setIsVisible(false);
  //   setTimeout(() => {
  //     dispatch(
  //       findAllProducts({
  //         token: token,
  //         page: currentPage,
  //         limit: itemsPerPage,
  //         code,
  //         name,
  //       })
  //     );
  //   }, [3000]);
  // };

  // const visibleEditHandel = (productId) => {
  //   setIsEditVisible(true);
  //   setProductId(productId);
  // };
  // const closeEdithandle = () => {
  //   setIsEditVisible(false);
  // };

  // const visibleFileHandel = () => {
  //   setIsFileVisible(true);
  // };
  const closeFilehandle = () => {
    setIsFileVisible(false);
  };

  // const closeDeletehandle = () => {
  //   setIsDeleteVisible(false);
  //   setTimeout(() => {
  //     dispatch(
  //       findAllProducts({
  //         token: token,
  //         page: currentPage,
  //         limit: itemsPerPage,
  //         code,
  //         name,
  //       })
  //     );
  //   }, [1000]);
  // };

  // const handleDeleteProduct = (productId) => {
  //   setProductId(productId);
  //   setIsDeleteVisible(true);
  // };

  // useEffect(() => {
  //   setTitle("Products");
  //   dispatch(
  //     findAllProducts({
  //       token: token,
  //       page: currentPage,
  //       limit: itemsPerPage,
  //       code,
  //       name,
  //     })
  //   );
  // }, [token, currentPage, itemsPerPage, code, name]);

  const data = [
    {
      id: 3,
      productionLine: "1",
      product: "aaa",
      component: "kkk",
      defect: "www",
      category: "www",
      user: "Ahmed",
      dateTime: "18 SEP 2023",
    },
    {
      id: 3,
      productionLine: "2",
      product: "dsf",
      component: "aer",
      defect: "adwad",
      category: "aser",
      user: "Mohmed",
      dateTime: "18 OCT 2023",
    },
    {
      id: 3,
      productionLine: "2",
      product: "dsf",
      component: "aer",
      defect: "adwad",
      category: "aser",
      user: "Mohmed",
      dateTime: "18 OCT 2023",
    },
    {
      id: 3,
      productionLine: "2",
      product: "dsf",
      component: "aer",
      defect: "adwad",
      category: "aser",
      user: "Mohmed",
      dateTime: "18 OCT 2023",
    },
  ];

  const header = [
    t("productionLine"),
    t("products"),
    t("components"),
    t("defects"),
    t("category"),
    t("users"),
    t("date"),
    t("actions"),
  ];
  let filteredData = (dataT) => {
    setCurrentItems(dataT);
  };

  // const allData = currentItems?.map((item, index) => {
  const allData = data?.map((item, index) => {
    return (
      <tr key={index}>
        <td className="project-code">
          <h6>{item.productionLine}</h6>
        </td>

        <td className="project-name">
          <h6>{item.product}</h6>
        </td>
        <td>{item.component}</td>
        <td>{item.category}</td>
        <td>{item.defect}</td>
        <td>{item.user}</td>
        <td>{item.dateTime}</td>

        <td className="project-actions">
          {/* <Link
            // to={`/admin/products/${item.id}`}
            className="btn btn-outline-secondary mr-1"
          >
            <i className="icon-picture"></i>
          </Link> */}
           <div
              className="btn btn-outline-secondary mr-1"
              onClick={() => setIsVisible(!isVisible)}
            >
              <i className="icon-picture"></i>
            </div>
          <div
            className="btn btn-outline-secondary"
            // onClick={() => visibleEditHandel(item.id)}
          >
            <i className="icon-pencil"></i>
          </div>
          <div
            className="btn btn-outline-danger danger "
            // onClick={() => handleDeleteProduct(item.id)}
          >
            <i className="icon-trash"></i>
          </div>
        </td>
      </tr>
    );
  });

  return (
    <>
      {/* <PopUp
      text={<DeleteProduct closePopup={closeDeletehandle} id={productId} />}
      isVisible={isDeleteVisible}
    /> */}
   
    {/* {isVisible && (
      <DefectImage closePopup={closeImagehandle} />
    )} */}
     {isVisible && (
        <DefectImage closePopup={() => setIsVisible(false)} />
      )}
      <div id="main-content">
        <BackButton />
        {/* <PopUp
        text={<ProductEdit closePopup={closeEdithandle} id={productId} />}
        isVisible={isEditVisible}
      /> */}
        <PopUp
          text={<UploadFile closePopup={closeFilehandle} />}
          isVisible={isFileVisible}
        />
        {/* <PopUp
        text={<ProductsAdd closePopup={closehandle} />}
        isVisible={isVisible}
      /> */}
        <div className="flex-header">
          <PageTitle Title={t("occurrences")} />
          <div className="btn-style" style={{ height: "35px" }}>
            <div
              className="btn btn-outline-secondary"
              onClick={() => setFilterVisabilty(!filterVisabilty)}
            >
              <i className="icon-equalizer plus"></i>
            </div>

            <div className="user-account m-0">
            <Link
                to={'/admin/qms/forms'}
                className="btn btn-outline-secondary mr-1"
              >
                <i className="icon-plus plus"></i>
              </Link>
            </div>
          </div>
        </div>
        {filterVisabilty && (
          <div className="card">
            <div className="inputs-search">
              <div className="">
                <input
                  className="form-control"
                  placeholder={t("Production line")}
                  type="text"
                  onChange={(e) => {
                    setProductionLine(e.target.value);
                  }}
                />
              </div>
              <div className="">
                <input
                  className="form-control"
                  placeholder={t("Component")}
                  type="text"
                  onChange={(e) => {
                    setProduct(e.target.value);
                  }}
                />
              </div>
              <div className="">
                <input
                  className="form-control"
                  placeholder={t("Component")}
                  type="text"
                  onChange={(e) => {
                    setComponent(e.target.value);
                  }}
                />
              </div>
              <div className="">
                <input
                  className="form-control"
                  placeholder={t("Defect")}
                  type="text"
                  onChange={(e) => {
                    setDefect(e.target.value);
                  }}
                />
              </div>
              <div className="">
                <input
                  className="form-control"
                  placeholder={t("Category")}
                  type="text"
                  onChange={(e) => {
                    setCategory(e.target.value);
                  }}
                />
              </div>
              <div className="">
                <input
                  className="form-control"
                  placeholder={t("User")}
                  type="text"
                  onChange={(e) => {
                    setUser(e.target.value);
                  }}
                />
              </div>
              <div className="">
                <Calendar
                  // value={dates}
                  onChange={(e) => setDateTime(e.value)}
                  selectionMode="range"
                  readOnlyInput
                  placeholder="Date"
                  // showTime={true}
                />
              </div>
            </div>
          </div>
        )}
        {loading ? (
          <PagesLoading />
        ) : (
          <>
            <TableList header={header} allData={allData} />
            <TablePagination
              data={responseData?.products}
              filterData={filteredData}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              responseData={responseData}
            />
          </>
        )}
      </div>
    </>
  );
};

export default Defects;
